import React, { useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import useScrollDistance from '../hooks/useScrollDistance';
import { pageMapper } from '../utils/constants.ts';
import { cn } from '../utils/helpers.ts';
import NavigationBar, { navigationBackground, navigationTheme } from '../components/container/NavigationBar';
import NavigationContent from '../components/container/NavigationContent';

function AppContainer() {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const location = useLocation();

  const ref = useRef();
  const distance = useScrollDistance({ range: 50, container: ref });

  const maxWidth = 'max-w-[1440px]';
  const { pathname } = location;

  // In mobile view, the ProgressBar is integrated with the navigation bar
  const locationWithProgressBar = [
    pageMapper.aboutYou,
    pageMapper.invoices,
    pageMapper.transactions,
    pageMapper.offer,
    pageMapper.offerSuccess,
    pageMapper.feedbackSuccess,
    pageMapper.newToTheBank,
  ];

  const normalTheme = {
    theme: navigationTheme.SECONDARY_SECONDARY,
    background: navigationBackground.gray,
  };

  const themeMapper = {
    [pageMapper.home]: {
      background: navigationBackground.transparent,
      theme: navigationTheme.WHITE_SECONDARY,
    },
    [pageMapper.getTransactions]: {
      background: navigationBackground.transparent,
      theme: navigationTheme.SECONDARY_SECONDARY,
    },
    [pageMapper.faq]: {
      background: navigationBackground.transparent,
      theme: navigationTheme.SECONDARY_SECONDARY,
    },
    [pageMapper.contact]: {
      background: navigationBackground.transparent,
      theme: navigationTheme.SECONDARY_SECONDARY,
    },
    [pageMapper.cookies]: {
      background: navigationBackground.white,
      theme: navigationTheme.SECONDARY_SECONDARY,
    },
    [pageMapper.compareProducts]: {
      background: navigationBackground.white,
      theme: navigationTheme.SECONDARY_SECONDARY,
    },
    [pageMapper.shorttermLoan]: {
      background: navigationBackground.transparent,
      theme: navigationTheme.WHITE_SECONDARY,
    },
  };

  const showProgressBar = locationWithProgressBar.includes(pathname);
  const { background, theme } = themeMapper[pathname] ?? normalTheme;

  return (
    <div id="app_container" className="relative flex flex-col items-center w-full h-full overflow-x-hidden overflow-y-auto lg:overflow-y-scroll" ref={ref}>
      <NavigationBar
        isOpen={navIsOpen}
        showProgressBar={showProgressBar}
        theme={theme}
        setIsOpen={setNavIsOpen}
        maxWidth={maxWidth}
        background={background}
        distance={distance}
        parentRef={ref}
      >
        <NavigationContent
          distance={distance}
          setIsOpen={setNavIsOpen}
          isOpen={navIsOpen}
        />
      </NavigationBar>

      {/* An <Outlet> renders whatever child route is currently active */}
      <div className={cn(maxWidth, 'flex flex-col w-full h-full justify-between')}>
        <Outlet context={ref} />
      </div>
    </div>
  );
}

export default AppContainer;
