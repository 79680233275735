import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutGroup } from 'framer-motion';
import Lottie from 'lottie-react';
import { getCaseState, getOffers, type JourneyBlocks } from '../../services/invoice-finance-api';
import usePageMeasure from '../../hooks/usePageMeasure';
import { pageMapper } from '../../utils/constants';
import { offerErrors, type RejectionError } from '../../utils/errors';
import { loop } from '../../utils/helpers';
import { isKeyOf } from '../../utils/predicates';
import { StoreContext } from '../../utils/store';
import ContactDetails from '../../components/container/ContactDetails';
import type { Step } from '../../components/container/ProgressSteps';
import ProgressSteps from '../../components/container/ProgressSteps';
import SupportedBanks from '../../components/container/SupportedBanks';
import PageLayout from '../../components/layout/PageLayout';
import Divider from '../../components/ui/Divider';
import Header from '../../components/ui/Header';
import Notification from '../../components/ui/Notification';
import CoinAnimation from '../../public/animations/loading_coin.json';

type GetOfferStep = Step & {
  name: JourneyBlocks;
};

function GetOffers() {
  const [activeId, setActiveId] = useState(1);

  const navigate = useNavigate();
  usePageMeasure({ name: 'getOffers' });

  const { actions } = useContext(StoreContext);
  const { setOffers } = actions;

  const steps: GetOfferStep[] = [
    {
      id: 1,
      name: 'FETCH_SALTEDGE_TRANSACTIONS',
      header: 'text_66e8184aefa5f600faf663b3',
      text: {
        key: 'text_66e8184aefa5f600faf663b4',
      },
      ref: useRef<HTMLDivElement>(null),
    },
    {
      id: 2,
      name: 'TRANSACTION_ANALYSIS',
      header: 'text_66e8184aefa5f600faf663b5',
      text: {
        key: 'text_66e8184aefa5f600faf663b6',
      },
      ref: useRef<HTMLDivElement>(null),
    },
    {
      id: 3,
      name: 'OFFERS_AVAILABLE',
      header: 'text_66e8184aefa5f600faf663b7',
      text: {
        key: 'text_66e8184aefa5f600faf663b8',
      },
      ref: useRef<HTMLDivElement>(null),
    },
  ];

  const onNoResults = () => {
    const error = 'TIMEOUT';
    navigate(pageMapper.requestFailed, { state: { error } });
  };

  const onError = (error: RejectionError) => {
    navigate(pageMapper.requestFailed, { state: { error } });
  };

  const handleOffers = async () => {
    try {
      const { data } = await getOffers();
      const { offersList, errorCode, isEligibleForDls } = data;

      const offerKey = isKeyOf(offerErrors, errorCode) && offerErrors[errorCode];
      const isRedScore = offerKey === offerErrors.ERR_6001;

      if (isRedScore && isEligibleForDls) {
        onError('DLS_ELIGIBILE_RED_SCORE');
        return true;
      }

      if (isRedScore) {
        onError('RED_SCORE');

        return true;
      }

      if (offersList?.length > 0) {
        setOffers(offersList);
        navigate(pageMapper.offer);

        return true;
      }
    } catch (err) {
      onError('GENERIC');
    }

    return false;
  };

  const handleCaseState = async () => {
    try {
      const { data } = await getCaseState();
      const { journeyBlocks } = data;

      steps.forEach(({ id, name }) => {
        const { state } = journeyBlocks[name];

        const isNextStep = activeId < id;
        const isSuccess = state === 'SUCCESS';

        if (isNextStep && isSuccess) {
          setActiveId(id);
        }
      });

      const isFinished = steps.every(({ name }) => journeyBlocks[name]?.state === 'SUCCESS');
      const isFailure = steps.some(({ name }) => journeyBlocks[name]?.state === 'FAILED');

      if (!isFinished) {
        return false;
      }

      if (isFailure) {
        onError('GENERIC');

        return true;
      }

      loop({
        limit: 30 * 5,
        delay: 2000,
        fn: handleOffers,
        callback: onNoResults,
      });
    } catch (err) {
      onError('GENERIC');
    }

    return true;
  };

  useEffect(() => {
    const interval = loop({
      delay: 2000,
      limit: 30 * 5,
      fn: handleCaseState,
      callback: onNoResults,
    });

    return () => clearInterval(interval);
  }, []);

  return (
    <LayoutGroup>
      <PageLayout>
        <PageLayout.MainColumn className="flex-col items-center py-8 gap-5 h-full">
          <PageLayout.PageProgressBar />

          <div className="flex flex-col max-w-md gap-5">
            <Lottie
              animationData={CoinAnimation}
              loop
              style={{ height: 300 }}
              className="max-h-32 lg:max-h-48"
            />

            <div className="flex flex-col gap-5">
              <Header
                langKey="text_66e8184aefa5f600faf663b1"
                element="h1"
                size="h3"
                className="text-center"
              />
            </div>

            <Divider className="w-full" />

            <Notification
              className="w-full"
              titleKey="text_65dcaec4742ac30076a354e1"
              langKey="text_65dcaec4742ac30076a354e2"
              type="warning"
            />

            <ProgressSteps
              activeId={activeId}
              showLoader
              steps={steps}
            />
          </div>
        </PageLayout.MainColumn>

        <PageLayout.SideColumn>
          <SupportedBanks
            className="hidden lg:flex"
            variant="side"
          />
          <ContactDetails />
        </PageLayout.SideColumn>
      </PageLayout>
    </LayoutGroup>
  );
}

export default GetOffers;
