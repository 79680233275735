import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../components/ui/Button.tsx';
import Dropdown from '../../components/ui/Dropdown.tsx';

function FaqPicker({ selectedOption, setSelectedOption, options }) {
  const { t } = useTranslation();
  const { control } = useForm();

  const component = 'faq';
  const inputName = 'faq_option';

  return (
    <>
      <Dropdown
        options={options}
        displayValue={(value) => value?.title}
        control={control}
        placeholder={t(`${component}.input.placeholder`)}
        name={inputName}
        className="lg:hidden"
        handleChange={setSelectedOption}
      />

      <div className="hidden w-full justify-between gap-8 px-4 lg:flex">
        {options.map((option) => {
          const { title, id } = option;
          const isSelected = title === selectedOption?.title;

          return (
            <Button
              name={`${component}_${title}`}
              onClick={() => setSelectedOption(!isSelected && option)}
              label={title}
              key={id}
              size="sm"
              variant={isSelected
                ? 'base'
                : 'outline'}
            />
          );
        })}
      </div>
    </>
  );
}

export default FaqPicker;
