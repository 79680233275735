import React, { type ReactNode, type RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { motion } from 'framer-motion';
import { adornmentAlignment, storageKeys } from '../../utils/constants';
import { cn, joinValues } from '../../utils/helpers';
import ChevronIcon, { type ChevronColors, type ChevronVariants } from '../ui/Chevron';
import Header, { type HeaderColors, type HeaderSizes, type HeaderWeights } from '../ui/Header';

/**
 * base       Basic accordion with the chevron at the end of the title.
 * vertical   The chevron is vertical aligned with the title and when the
 *            accordion opens, the chevron will move downwards having the content
 *            in between.
 */
const variants = {
  base: 'base',
  vertical: 'vertical',
  border: 'border',
};

const sizeOptions: Partial<Record<HeaderSizes, string>> = {
  sm: 'leading-[1.375rem]',
  md: 'leading-[1.375rem]',
};

const chevronAdjustments: { [K in ChevronVariants]: { [P in HeaderSizes]?: string } } = {
  bold: {
    h1: 'mt-[5px] lg:mt-[22px]',
    h2: 'mt-2 lg:mt-[22px]',
    h3: 'mt-[5px] lg:mt-[11px]',
    h4: 'mt-0.5 lg:mt-[7px]',
    h5: 'lg:mt-1',
  },
  thin: {
    h1: 'mt-[11px] lg:mt-[26px]',
    h2: 'mt-3.5 lg:mt-[26px]',
    h3: 'mt-[11px] lg:mt-[14px]',
    h4: 'mt-[7px] lg:mt-[11px]',
    h5: 'mt-1.5',
    sm: 'mt-[7px]',
    md: 'mt-[7px]',
  },
};

type LabelProps = {
  className?: string;
  weight?: HeaderWeights;
  label?: string;
  size: HeaderSizes;
  color?: HeaderColors;
};

function Label({ className, label, color, size, weight }: LabelProps) {
  if (!label) {
    return null;
  }

  return (
    <Header
      className={className}
      element="h3"
      color={color}
      size={size}
      weight={weight}
    >
      {label}
    </Header>
  );
}

type AccordionProps = {
  className?: string;
  disabled?: boolean;
  size?: HeaderSizes;
  langKey?: string;
  label?: string;
  variant?: keyof typeof variants;
  beginAdornment?: ReactNode;
  children: ReactNode;
  chevronPosition?: keyof typeof adornmentAlignment;
  chevronType?: ChevronVariants;
  weight?: HeaderWeights;
  padding?: string;
  isActive?: boolean;
  bottomOffset?: number;
  color?: HeaderColors;
  chevronColor?: ChevronColors;
};

function Accordion({
  className,
  beginAdornment,
  disabled,
  children,
  langKey,
  label,
  padding,
  isActive,
  color,
  weight,
  bottomOffset = 0,
  size = 'h3',
  variant = 'base',
  chevronPosition = 'top',
  chevronType = 'bold',
  chevronColor = 'secondary',
}: AccordionProps) {
  const options = sizeOptions[size];
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(isActive);
  const [height, setHeight] = useState(0);

  const value = Number(sessionStorage.getItem(storageKeys.navigationHeight));
  const navigationHeight = Number.isNaN(value) ? 0 : value;

  const parentRef: RefObject<HTMLDivElement> = useOutletContext();
  const ref = useRef<HTMLDivElement>(null);

  const chevronCorrection = chevronAdjustments[chevronType][size];
  const offset = 50;

  const translation = langKey && t(langKey);
  const header = label ?? translation;

  const scrollTo = (position: number) => {
    parentRef.current?.scroll({
      top: position,
      behavior: 'smooth',
    });
  };

  // When the accordion is opened and it's a the bottom of the screen the
  // view has to scroll up in order to show the user the opened state of
  // the accordion.
  //
  // When the height of the accordion in an open state is greater than
  // height of the view, it needs to move the accordion to the top with
  // navigation bar height substracted.
  const scrollUp = () => {
    if (!isOpen || !parentRef.current || !ref.current) {
      return;
    }

    const { scrollTop, offsetHeight: parentHeight } = parentRef.current;
    const { offsetTop, offsetHeight } = ref.current;

    const bottom = scrollTop + parentHeight - bottomOffset;

    const heightClosed = offsetTop + height + offset;
    const heightOpened = offsetTop + offsetHeight;

    if (bottom >= offsetTop && bottom <= heightClosed) {
      const top = offsetTop - (navigationHeight ?? 0);
      const bottomRefDifference = heightOpened - bottom;

      scrollTo(Math.min(top, scrollTop + Math.max(0, bottomRefDifference)));
    }
  };

  // The full height of the accordion in an opened state is only available
  // after the animation of opening the accordion is finished. In order to
  // check if the accordion is at the bottom, the original height is needed.
  useEffect(() => {
    setHeight(ref.current?.offsetHeight ?? 0);
  }, []);

  useEffect(() => {
    if (!isActive || !ref.current) {
      return;
    }

    scrollTo(ref.current.offsetTop - navigationHeight);
  }, []);

  return (
    <div
      className={joinValues({
        options: className,
        base: 'flex flex-col justify-between w-full',
      })}
      ref={ref}
    >
      {/* Base variant */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        aria-label="accordion_control"
        className={joinValues({
          visibility: variant === 'vertical' && 'hidden',
          base: 'flex justify-between gap-6',
          alignment: adornmentAlignment[chevronPosition],
          padding,
          variant: cn(
            variant === 'border' && 'py-5 border-t border-gray-medium',
          ),
        })}
        disabled={disabled}
      >
        <div className="flex items-start gap-4 text-left">
          {beginAdornment && beginAdornment}
          <Label
            label={header}
            color={color}
            size={size}
            weight={weight}
            className={cn(options, disabled && 'text-gray-dark')}
          />
        </div>
        <ChevronIcon
          className={joinValues({
            base: 'flex-shrink-0',
            animation: 'ease-out duration-200',
            correction: chevronPosition === 'top' && chevronCorrection,
          })}
          direction={isOpen ? 'up' : 'down'}
          disabled={disabled}
          color={isOpen ? 'primary' : chevronColor}
          variant={chevronType}
        />
      </button>

      {/* Vertical variant */}
      <Label
        label={header}
        color={color}
        size={size}
        className={joinValues({
          visibility: variant !== 'vertical' && 'hidden',
          base: 'text-center mb-6',
          padding,
          disabled: disabled && 'text-gray-dark',
        })}
      />

      <motion.div
        initial={{ height: 0 }}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={{ duration: 0.2 }}
        className="overflow-hidden"
        onAnimationComplete={scrollUp}
      >
        <div className={cn(variant !== 'border' && 'mt-2')}>
          {children}
        </div>
      </motion.div>

      {/* Vertical variant */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        aria-label="accordion_control"
        className={joinValues({
          visibility: variant !== 'vertical' && 'hidden',
          spacing: isOpen && 'mt-6',
          base: 'flex justify-center pb-4',
          padding,
        })}
        disabled={disabled}
      >
        <ChevronIcon
          className={joinValues({
            disabled: disabled ? 'fill-gray-dark' : 'fill-primary',
            animation: 'ease-out duration-200',
          })}
          direction={isOpen ? 'up' : 'down'}
          variant={chevronType}
        />
      </button>
    </div>
  );
}

export default Accordion;
