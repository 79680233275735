import { delay, http, HttpResponse } from 'msw';
import { isBlob } from '../utils/predicates.ts';
import { requester1 } from '../e2e/fixtures/requester';
import authentication from './fixtures/authentication';
import companies from './fixtures/companyDetails';
import eligibility from './fixtures/eligibility';
import { createInvoice, createInvoiceScan } from './fixtures/invoice';
import offers from './fixtures/offers';
import {
  connections,
  customerId,
  status,
  transactionInfo,
} from './fixtures/saltedge';

const apiUrl = process.env.REACT_APP_API_URL;

const FIVE_DAYS = 5;

let caseStateCount = 0;

// --------------------------------------------------
// ------------------- Happy Flow -------------------
// --------------------------------------------------

// authentication token
const getJwtToken = http.post(`${apiUrl}/dls-authentication/v3/authentication/invfin/anonymous-tokens`, () => (
  HttpResponse.json(authentication, { status: 200 })
));

const analytics = http.post(`${apiUrl}/dls-invoice-finance-api/v6/analytics`, () => (
  new HttpResponse(null, { status: 202 })
));

// case state
const getCaseState = http.get(`${apiUrl}/dls-invoice-finance-api/v6/case-state`, () => {
  const state = {
    FETCH_SALTEDGE_TRANSACTIONS: {
      state: caseStateCount >= 2 ? 'SUCCESS' : 'PENDING',
    },
    TRANSACTION_ANALYSIS: {
      state: caseStateCount >= 4 ? 'SUCCESS' : 'PENDING',
    },
    OFFERS_AVAILABLE: {
      state: caseStateCount >= 6 ? 'SUCCESS' : 'PENDING',
    },
  };

  caseStateCount += 1;

  const response = {
    journeyBlocks: state,
    origin: 'DLS',
    offering: 'INVFIN',
  };

  return HttpResponse.json(response, { status: 200 });
});

// company check
const companySearch = http.get(`${apiUrl}/dls-invoice-finance-api/v6/company/search`, ({ request }) => {
  const url = new URL(request.url);
  const search = url.searchParams.get('query');

  const requester = companies.filter((company) => company.name.toLowerCase().includes(search));

  const companyDetails = requester.length === 0
    ? companies
    : requester;

  return HttpResponse.json(companyDetails, { status: 200 });
});

// requester eligibility
const requesterEligibility = http.post(`${apiUrl}/dls-invoice-finance-api/v6/requester-eligibility`, async ({ request }) => {
  const data = await request.json();
  const { kvkNumber } = data;

  const [requester] = companies.filter((company) => company.kvkNumber === kvkNumber);
  const { newToTheBank } = requester;

  eligibility.newToTheBank = newToTheBank;

  return HttpResponse.json(eligibility, { status: 200 });
});

// contact details
const contactDetails = http.post(`${apiUrl}/dls-invoice-finance-api/v6/contact-details`, () => (
  new HttpResponse(null, { status: 202 })
));

// upload invoice
const uploadInvoice = http.post(`${apiUrl}/dls-nh-ocr-integration/v1/parse-document`, () => {
  const invoice = createInvoiceScan({
    amount: 1000000,
    issueDate: new Date(),
    invoiceId: 'Invoice A',
    period: 30,
    debtorName: '',
    debtorKvk: '',
    companyName: '',
    companyKvk: requester1.kvkNumber,
  });

  return HttpResponse.json(invoice, { status: 200 });
});

// invoices
const addInvoice = http.post(`${apiUrl}/dls-invoice-finance-api/v6/invoices`, async ({ request }) => {
  const formData = await request.formData();

  const invoiceBlob = formData.get('invoiceDetails');
  const invoiceIsBlob = isBlob(invoiceBlob);

  if (!invoiceIsBlob) {
    const invoice = createInvoice();

    return HttpResponse.json(invoice, { status: 200 });
  }

  const invoiceDataText = await invoiceBlob.text();

  const invoiceData = JSON.parse(invoiceDataText);
  const invoice = createInvoice(invoiceData);

  return HttpResponse.json(invoice, { status: 200 });
});

// transaction consent
const consent = http.post(`${apiUrl}/dls-invoice-finance-api/v6/financials/consent`, () => (
  new HttpResponse(null, { status: 204 })
));

// create psd2 session
const createSession = http.post(`${apiUrl}/dls-psd2-aggregator/v1/create/session`, async ({ request }) => {
  const data = await request.json();
  const { returnUrl } = data;

  const date = new Date();
  date.setDate(date.getDate() + FIVE_DAYS);

  const session = {
    connectUrl: returnUrl,
    expiresAt: date,
    customer: customerId,
  };

  return HttpResponse.json(session, { status: 200 });
});

// connection id
const getConnections = http.get(`${apiUrl}/dls-psd2-aggregator/v1/fetch/connections`, () => (
  HttpResponse.json({ connections }, { status: 200 })
));

// psd2 connection
const createConnection = http.get(`${apiUrl}/dls-psd2-aggregator/v1/fetch/connection/:id`, async () => {
  await delay(2000);

  return HttpResponse.json({ finished: true }, { status: 200 });
});

// psd2 transactions
const getTransactions = http.post(`${apiUrl}/dls-invoice-finance-api/v6/transactions/saltedge/fetch-transactions`, () => (
  HttpResponse.json(transactionInfo, { status: 200 })
));

// remove transactions
const removeTransactions = http.delete(`${apiUrl}/dls-transaction-processing/v1/financials/remove-transactions`, () => (
  new HttpResponse(null, { status: 204 })
));

// psd2 status
const getTransactionStatus = http.get(`${apiUrl}/dls-transaction-processing/v1/financials/psd2/status`, () => (
  HttpResponse.json(status, { status: 200 })
));

// transaction process
const transactionProcess = http.post(`${apiUrl}/dls-transaction-processing/v3/financials/confirm`, () => (
  new HttpResponse(null, { status: 204 })
));

// risk evaluation
const riskEvaluation = http.post(`${apiUrl}/dls-invoice-finance-api/v6/risk-evaluation`, () => (
  HttpResponse.json({ eligible: true }, { status: 200 })
));

// offers
const getOffers = http.get(`${apiUrl}/dls-invoice-finance-api/v6/offers`, async () => {
  await delay(2000);

  return HttpResponse.json(offers, { status: 200 });
});

// new to the bank
const newToTheBank = http.post(`${apiUrl}/dls-invoice-finance-api/v6/survey-eligibility`, () => (
  HttpResponse.json({ eligible: true, message: '[]' }, { status: 200 })
));

// confirm offer
const confirmOffer = http.post(`${apiUrl}/dls-invoice-finance-api/v6/confirm-offer`, () => (
  new HttpResponse(null, { status: 202 })
));

// feedback
const sendFeedback = http.post(`${apiUrl}/dls-invoice-finance-api/v6/feedback`, () => (
  new HttpResponse(null, { status: 204 })
));

// --------------------------------------------------
// ---------------------- MISC ----------------------
// --------------------------------------------------

// contact form
const sendMessage = http.post(`${apiUrl}/dls-contact-details/v1/contact-form`, async () => {
  await delay(2000);

  return new HttpResponse(null, { status: 204 });
});

// edit invoice
const editInvoice = http.put(`${apiUrl}/dls-invoice-finance-api/v6/invoice/:id`, async ({ request, params }) => {
  const invoiceData = await request.json();
  const { id } = params;

  const invoice = createInvoice({
    ...invoiceData,
    invoiceId: id,
  });

  return HttpResponse.json(invoice, { status: 200 });
});

// remove invoice
const deleteInvoice = http.delete(`${apiUrl}/dls-invoice-finance-api/v6/invoice/:id`, async () => {
  await delay(2000);

  return new HttpResponse(null, { status: 204 });
});

// authentication (rabobank branded flow)
const getRedirectToken = http.post(`${apiUrl}/dls-authentication/v1/authentication/redirect/authorize`, () => (
  HttpResponse.json(authentication, { status: 200 })
));

const handlers = [
  getJwtToken,
  analytics,
  getCaseState,
  companySearch,
  requesterEligibility,
  contactDetails,
  uploadInvoice,
  addInvoice,
  consent,
  createSession,
  getConnections,
  createConnection,
  getTransactions,
  removeTransactions,
  getTransactionStatus,
  transactionProcess,
  riskEvaluation,
  getOffers,
  newToTheBank,
  confirmOffer,
  sendFeedback,
  sendMessage,
  editInvoice,
  deleteInvoice,
  getRedirectToken,
];

export default handlers;
