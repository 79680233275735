import { useContext, useEffect, useState } from 'react';
import { featureFlags, storageKeys } from '../utils/constants';
import { StoreContext } from '../utils/store';
import useSplit from './useSplit';

function useRemoveToken() {
  const { auth } = featureFlags;
  const { isReady, treatment } = useSplit(auth);

  const { state, actions } = useContext(StoreContext);

  const { requester } = state;
  const { removeRequester } = actions;

  const [tokenRemoved, setTokenRemoved] = useState(false);

  useEffect(() => {
    if (!isReady || treatment !== 'on') {
      return;
    }

    const token = sessionStorage.getItem(storageKeys.jwt);
    const { kvkNumber } = requester;

    if (!token || !kvkNumber) {
      return;
    }

    sessionStorage.removeItem(storageKeys.jwt);
    removeRequester();

    const shouldRemoveToken = Boolean(token && kvkNumber);
    setTokenRemoved(shouldRemoveToken);
  }, [isReady]);

  return tokenRemoved;
}

export default useRemoveToken;
